import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Image from "../components/Image";
import SEO from "../components/seo";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from 'react-bootstrap';
import "../components/main.css";
import Mid1 from "../components/Mid1";
import Mid2 from "../components/Mid2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import { faHeart } from '@fortawesome/free-regular-svg-icons'

const divStyle = {
  position: 'absolute',
  top: '350px',
  left: '110px',
  color: 'white',
  paddingLeft: '20px',
  paddingRight: '20px'
};

const imgStyle = {
  width: '100%',
  height: '100%',
  position: 'relative'
}

const heroFont = {
  fontSize: '60px'
}

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Home" keywords={[`monkey mind adventure`, `trekking`, `adventure`, `mountaineering`, `mountain`, `nepal`]} />
    <div className="home">
      <Image />
      <section style={divStyle}>
        <h1 style={heroFont}>Experience </h1>
        <h1 style={heroFont}>your Next Adventure!</h1>
      </section>
      <Mid2 />

      {/* First */}
      <Container>
      <div className="title-with-btn">
        <h2>popular adventures</h2>
      </div>
        <Row>
          {data.allContentfulAdventure.edges.map((edge) => (
            <Col md>
              <Link className="anchor-style" to={`/adventure/${edge.node.slug}`} key={edge.node.id}>
                  <div className="product-link">
                    <img style={imgStyle} alt={''} src={edge.node.image.fluid.src} />
                    <div className="product-content">
                      <div className="product-meta">
                        <div className="product-duration">
                          <span>{edge.node.duration}</span>
                        </div>
                        <div className="product-location">
                          <span> {edge.node.location}</span>
                          </div>
                      </div>
                      <h5>{edge.node.title}</h5>
                      <p>{edge.node.description}</p>
                      <div className="product-price-tag">
                        <span>FROM ${edge.node.price}</span>
                      </div>
                      <div className="product-ratings">
                        <span className="star-rating">
                          <FontAwesomeIcon className="txt-gold" icon={faStar} />
                          <FontAwesomeIcon className="txt-gold" icon={faStar} />
                          <FontAwesomeIcon className="txt-gold" icon={faStar} />
                          <FontAwesomeIcon className="txt-gold" icon={faStar} />
                          <FontAwesomeIcon className="txt-gold" icon={faStarHalfAlt} />
                        </span>
                      </div>
                      <div className="shortlist-button">
                        <span className="save-button">
                        <FontAwesomeIcon className="txt-grey" icon={faHeart} />
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
            </Col>
          ))}
        </Row>
      </Container>
      <Mid1 />

      {/* Second */}
      <Container>
        <div className="title-with-btn">
          <h2>Award winning Adventure holidays</h2>
        </div>
        <Row>
          {data.allContentfulPost.edges.map((edge) => (
            <Col md>
              <Link className="anchor-style" to={`/post/${edge.node.slug}`} key={edge.node.id}>
                <div className="product-link">
                  <img style={imgStyle} alt={''} src={edge.node.image.fluid.src} />
                  <div className="product-content">
                    <h5>{edge.node.title}</h5>
                    <p>{edge.node.description}</p>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
      {/*<Link to="/posts/">View all posts</Link>*/}
    </div>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query LandingPostsPageQuery {
    allContentfulPost(limit: 3) {
      edges {
        node {
          id
          title
          description
          slug
          content {
            childContentfulRichText {
              html
            }
          }
          image {
            fluid(maxHeight: 500, maxWidth: 500) {
              src
            }
          }
        }
      }
    }
    allContentfulAdventure {
      edges {
        node {
          annualLeave
          id
          slug
          rating
          price
          startsEnds
          title
          description
          difficulty
          duration
          image {
            fluid(maxHeight: 500, maxWidth: 500) {
              src
            }
          }
          content {
            childContentfulRichText {
              html
            }
          }
          location
        }
      }
    }
  }
`;

import React from 'react';
import boat from '../images/boat.png'

const imgStyle = {
    width: '128px',
    verticalAlign: 'middle'
  };

  const topStyle = {
    textAlign: 'center',
    margin: '0 auto',
    padding: '5rem 0 2rem',
    maxWidth: '900px'
  }

  const h3Style = {
    borderBottom: '4px solid rgba(0, 0, 0, 0.87)',
    paddingBottom: '8px',
    paddingTop: '2px',
    marginBottom: '24px',
    fontSize: '32px',
    fontWeight: 'bold'
  }

  const pStyle = {
      fontSize: '22px',
      fontStyle: 'italic'
  }

const Mid1 = () => (
    <div style={topStyle}>
        <img style={imgStyle} src={boat} alt="boat" />
        <h3 style={h3Style}>JOIN OTHER OUTDOORSY PEOPLE IN WILD PLACES</h3>
        <p style={pStyle}>Our award-winning trips bring like-minded people together who want to squeeze more adventure into their busy lives.</p>
    </div>
)

export default Mid1;